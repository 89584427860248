import "./Home.css"

import React from 'react'
import { Globalalert, Navbar, Footer, Contact } from '../../components'
import { Helmet } from 'react-helmet'

function Home() {
    const globalAlertItems = {
        body: "My next pop up event will take place at the Solana Beach Farmers Market on [Insert DateValue] from 8am-12pm",
        shortBody: "Solana Beach Farmers Market 12-4PM",
        ctaText: "View on Google Maps",
        ctaURL: "https://www.google.com/search?q=444+S+Cedros+Avenue+Solana+Beach%2C+CA+92075&sourceid=chrome&ie=UTF-8#"
    }

    return (
        <div id="homepage" className="site">
            <Helmet>
                <title>Jacky Dam</title>
            </Helmet>
            
            <Globalalert globalAlertItems={ globalAlertItems }/>
            <Navbar />

            <section className="hero-section">
                <div className="hero-banner">
                    <div className="hero-container">
                        <ul className="hero-carousel-list">
                            <li className="hero-carousel" id="solanabeach">
                                <div className="carousel-container">
                                    <h1>Banh Mi Pop Up Meets San Diego!</h1>
                                    <p>Visit my popup event at the Solana Beach Farmers Market starting on [Insert DateValue] happening every Sunday between 12pm - 4pm.</p>
                                    <a href="https://www.google.com/search?q=444+S+Cedros+Avenue+Solana+Beach%2C+CA+92075&sourceid=chrome&ie=UTF-8#" className="btn-primary" target="_blank" rel="noopener noreferrer">
                                        <img src ="https://images.ctfassets.net/5n0rjy03mqb9/3mozS7pSfzjcEz394jYvna/94758a3ac3e14e65adc771719ac9b0cb/Google_Maps_Icon.png?w=35"></img>
                                        Get Directions
                                    </a>
                                </div>
                                <div className="image-container">
                                    <img src="https://images.ctfassets.net/5n0rjy03mqb9/2Pke4pHlnDcSWsayP7chpF/aba97a23299cb0bedff4015edf0bc425/Solana_Beach_Farmers_Market.jpg?w=680" className="hero-image" alt=""/>
                                </div>
                            </li>
                            {/* <li className="hero-carousel" id="delmar"></li> */}
                        </ul>
                    </div>

                    <div className="mini-carousel-container">
                        <ul className="mini-carousel-list">
                            <li className="mini-carousel-item" style={{ backgroundColor: "#C73535" }}>
                                <img src="https://images.ctfassets.net/5n0rjy03mqb9/3En8EWSQHGHHQ6CfejqzC0/43646441581a0f60d2d43f9b154ea71e/Farmer_Market_Icon.png?w=89" />
                                <div className="mini-carousel-content">
                                    <h1>Solana Beach Farmers Market</h1>
                                    <a href="">Details</a>
                                </div>
                            </li>
                            <li className="mini-carousel-item" style={{ backgroundColor: "#7C81F6" }}>
                                <img src="https://images.ctfassets.net/5n0rjy03mqb9/3En8EWSQHGHHQ6CfejqzC0/43646441581a0f60d2d43f9b154ea71e/Farmer_Market_Icon.png?w=89" />
                                <div className="mini-carousel-content">
                                    <h1>Del Mar Farmers Market</h1>
                                    <a href="">Details</a>
                                </div>
                            </li>
                            <li className="mini-carousel-item" style={{ backgroundColor: "#EB9494" }}>
                                <img src="https://images.ctfassets.net/5n0rjy03mqb9/60WSaHxt5LKdz3Q1Q4IEfo/211f009f4bccf4fcdab1c6cb2a2244fc/Cooking_Icon.png?w=89" />
                                <div className="mini-carousel-content">
                                    <h1>Check out my Youtube Channel</h1>
                                    <a href="">Details</a>
                                </div>
                            </li>
                            <li className="mini-carousel-item" style={{ backgroundColor: "#F8C202" }}>
                                <img src="https://images.ctfassets.net/5n0rjy03mqb9/4f4NPSHHW3kvwZKFxQwlP2/aa33a789769deb0d5b4ea1dbd8eeb5dc/Restaurant_Icon.png?w=89" />
                                <div className="mini-carousel-content">
                                    <h1>Fusion Speakeasy Restaurant</h1>
                                    <a href="">Details</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="mini-carousel-indicator-container">
                        <ul className="mini-carousel-indicator">
                            <li className="mini-carousel-indicator-item" aria-label="seen"></li>
                            <li className="mini-carousel-indicator-item"></li>
                            <li className="mini-carousel-indicator-item"></li>
                            <li className="mini-carousel-indicator-item"></li>
                        </ul>
                    </div>

                </div>
            </section>

            {/* <div className="service-bar">
                <div className="service-container">
                    <ol>
                        <li>🥡 Good Food</li>
                        <li>🍃 Locally Sourced</li>
                        <li>❤️ Made with Care</li>
                        <li>📚 Homemade Recipes</li>
                        <li>💯 Made Perfect</li>
                        <li>🍞 Fresh Bread</li>
                    </ol>
                </div>
            </div> */}

            <div className="about-section">
                <div className="about-container">
                    <h1>The Inspiration</h1>
                    <p>Being raised in Los Angeles, I know what what it means to experience REALLY good Asian food and be surrounded by like-minded people who can’t get enough of this foodie destination. My love for food and cooking is ultimately what motivates me to want to share this experience for my people in San Diego who just want good Asian food..</p>
                    <p>The secret Banh Mi creation I am sharing with you is a recipe that has been throughly perfected considering the many variations you can have with ingredients, ratio, and technique. The perfect Banh Mi itself is one where you get the savoriness from the grilled meat¹, tanginess from the pickled vegetables, a balance of freshness and spice all wrapped in a crispy Vietnamese Baguette.</p>
                </div>
                <div className="about-image-conatiner">
                    <img src="https://images.ctfassets.net/5n0rjy03mqb9/2mlUPSCq8kIH9vuwNvfaJ5/2e50b339c0b5bb7094e112f0efd3e10e/Jacky_In_a_Salt_Bae_Outfit.jpg?w=400"></img>
                </div>
            </div>
            <Contact />

            <Footer />

        </div >
    )
}

export default Home