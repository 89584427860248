import "./Footer.css"

function Footer() {
    return (
        <div className="footer">
            <ul>
                <li>Youtube</li>
                <li>Tiktok</li>
                <li>Instagram</li>
                <li>Youtube</li>
            </ul>

            <ul>
                <li>About Us</li>
                <li>Shop</li>
                <li>Help</li>
                <li>Contact</li>
            </ul>

            <ul>
                <li>paperfoldingcompany@gmail.com</li>
                <li>Solana Beach Farmers Market (Every Sunday 8am - 4pm)</li>
                <li>Google Maps</li>
                <li>Set Calendar Reminder</li>
            </ul>

        </div>
    );
}

export default Footer