import "./Globalalert.css"

function Globalalert( { globalAlertItems }) {
    const body = globalAlertItems["body"];
    const shortBody = globalAlertItems["shortBody"];
    const ctaText = globalAlertItems["ctaText"];
    const ctaURL = globalAlertItems["ctaURL"];

    return (
        <div className="global-banner">
            <ul className="long-banner">
                <li>
                    <span>{ body }</span>
                    <a href={ ctaURL }>{ ctaText }</a>
                </li>
            </ul>
            <ul className="long-banner">
                <li>
                    <span>{ body }</span>
                    <a href={ ctaURL } target="_blank" rel="noopener noreferrer">{ ctaText }</a>
                </li>
            </ul>

            <ul className="short-banner">
                <li>
                    <a href={ ctaURL }>{ shortBody }</a>
                    <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" class="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor"></path>
                    </svg>
                </li>
            </ul>


        </div>
    );
}

export default Globalalert