import "./Navbar.css"
import BuyMeACoffeeButton from "../Donation/BuyMeACoffeeButton"
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from './stussy.png'

import {
    FaHome
} from 'react-icons/fa';

function Navbar() {
    return (
        <nav className="main-navbar">
            {(
                <NavLink
                    to='/'>
                    <FaHome
                        className='landing--social'
                        style={{ color: "black" }}
                        aria-label='Github'
                    />
                </NavLink>
                
            )}
            <ul className="navbar-nav">
                <li className="navbar-item">
                    <NavLink
                        to='/menu'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                       View Menu
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink
                        to='/gallery'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Photo Gallery
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink
                        to='#contact'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Contact Us
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <a data-formkit-toggle="c18d61347c" href="https://jackydam.ck.page/c18d61347c">Join Email List</a>
                </li>
                <BuyMeACoffeeButton />
            </ul>

        </nav>
    );
}

export default Navbar